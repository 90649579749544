// routes
import Router from './routes';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// theme
import ThemeProvider from './theme';

// components
import { ChartStyle } from './components/chart';
import { ProgressBarStyle } from './components/ProgressBar';
import NotistackProvider from './components/NotistackProvider';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import { LiveChatLoaderProvider, Intercom } from 'react-live-chat-loader'

// ----------------------------------------------------------------------

export default function App() {
  const location = useLocation();

  useEffect(() => {
    if (window.Intercom != null) { window.Intercom('update', { last_request_at: Math.floor(new Date().getTime() / 1000) }) };
    if (window.rg4js != null) { window.rg4js('trackEvent', { type: 'pageView', path: location.pathname }); }
  }, [location]);

  const onIntercomReady = () => {
    if (window.Intercom != null) {
      window.Intercom("boot", global.UserInfo);
    }
  }

  if (process.env.REACT_APP_INTERCOM) {
    return (
      <LiveChatLoaderProvider providerKey={process.env.REACT_APP_INTERCOM!} provider="intercom" idlePeriod={500} onReady={onIntercomReady} >
        <MotionLazyContainer>
          <ThemeProvider>
            <NotistackProvider>
              <ProgressBarStyle />
              <ChartStyle />
              <Router />
            </NotistackProvider>
          </ThemeProvider>
        </MotionLazyContainer>
        <Intercom color="#00ab55" containerClass="intercom-placeholder-container" />
      </LiveChatLoaderProvider>
    );
  }
  else {
    return (
      <MotionLazyContainer>
        <ThemeProvider>
          <NotistackProvider>
            <ProgressBarStyle />
            <ChartStyle />
            <Router />
          </NotistackProvider>
        </ThemeProvider>
      </MotionLazyContainer>
    );
  }
}