import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Stack } from '@mui/material';
// hooks
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// config
import { HEADER, NAVBAR } from '../../config';
//
import DashboardHeader from './header';
import NavbarVertical from './navbar/NavbarVertical';
import useAuth from 'src/hooks/useAuth';
import { TaskOpenedActionCreator, TaskUpdateActionCreator } from 'src/redux/actions/TaskUpdateActionCreator';
import { useDispatch } from 'src/redux/store';
import { TaskItemCommentUpdateActionCreator } from 'src/redux/actions/TaskCommentUpdateActionCreator';
import { TaskScheduleEntryUpdateActionCreator } from '../../redux/actions/TaskScheduleEntryUpdateActionCreator';

// ----------------------------------------------------------------------

type MainStyleProps = {
  collapseClick: boolean;
};

const MainStyle = styled('main', {
  shouldForwardProp: (prop) => prop !== 'collapseClick',
})<MainStyleProps>(({ collapseClick, theme }) => ({
  display: 'flex',
  overflow: 'hidden',
  minHeight: '100vh',
  flexDirection: 'column',
  paddingTop: HEADER.MOBILE_HEIGHT + 24,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
    ...(collapseClick && {
      marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    }),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const { collapseClick, isCollapse } = useCollapseDrawer();
  const dispatch = useDispatch();
  const { connection, space, updateStatues, updateLink, updateNote } = useAuth();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (connection) {
      connection.off('StatusUpdates');
      connection.on('StatusUpdates', (spaceId, data) => {
        if (spaceId === space.id) {
          console.log('Statuses Update', data);
          updateStatues(data);
        }
        else {
          console.log(`StatusUpdates received for incorrect space received ${spaceId}`)
        }
      });

      connection.off('LinkUpdate');
      connection.on('LinkUpdate', (spaceId, data) => {
        if (spaceId === space.id) {
          console.log('Link update', data);
          updateLink(data);
        }
        else {
          console.log(`LinkUpdate received for incorrect space received ${spaceId}`)
        }
      });

      connection.off('NoteUpdate');
      connection.on('NoteUpdate', (spaceId, data) => {
        if (spaceId === space.id) {
          console.log('Note update', data);
          updateNote(data);
        }
        else {
          console.log(`NoteUpdate received for incorrect space received ${spaceId}`)
        }
      });

      connection.off('TaskItemUpdates');
      connection.on('TaskItemUpdates', (spaceId, data, _updatedBy) => {
        if (spaceId === space.id) {
          console.log('Tasks update', data);
          dispatch(TaskUpdateActionCreator(spaceId, data));
        }
        else {
          console.log(`TaskItemUpdates received for incorrect space received ${spaceId}`)
        }
      });

      connection.off('TaskItemOpened');
      connection.on('TaskItemOpened', (spaceId, taskId) => {
        if (spaceId === space.id) {
          console.log('Task opened', taskId);
          dispatch(TaskOpenedActionCreator(spaceId, taskId));
        }
        else {
          console.log(`TaskItemOpened received for incorrect space received ${spaceId}`)
        }
      });

      connection.off('TaskItemCommentUpdates');
      connection.on('TaskItemCommentUpdates', (spaceId, taskId, data) => {
        if (spaceId === space.id) {
          console.log(`Task comments update for task ${taskId}`, data);
          dispatch(TaskItemCommentUpdateActionCreator(spaceId, taskId, data));
        }
        else {
          console.log(`TaskItemCommentUpdates received for incorrect space received ${spaceId}`)
        }
      });

      connection.off('TaskItemScheduleEntryUpdates');
      connection.on('TaskItemScheduleEntryUpdates', (spaceId, taskId, data) => {
        if (spaceId === space.id) {
          console.log(`Task schedule updates for task ${taskId}`, data);
          dispatch(TaskScheduleEntryUpdateActionCreator(spaceId, taskId, data));
        }
        else {
          console.log(`TaskItemScheduleEntryUpdates received for incorrect space received ${spaceId}`)
        }
      });

      return () => {};
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connection?.connectionId]);

  return (
    <Stack
      direction={{
        xs: 'column',
        lg: 'row',
      }}
      sx={{
        minHeight: 1,
      }}
    >
      <DashboardHeader isCollapse={isCollapse} onOpenSidebar={() => setOpen(true)} />

      <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />

      <MainStyle collapseClick={collapseClick}>
        <Outlet />
      </MainStyle>
    </Stack>
  );
}
