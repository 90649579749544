import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { logout } from './slices/auth';
import { HOST_API } from '../config';
// ----------------------------------------------------------------------

// initialize an empty api service that we'll inject endpoints into later as needed
export const CacheTags = {
  Task: "tasks"
}
const baseQuery = fetchBaseQuery({
  baseUrl: HOST_API,
  prepareHeaders: (headers, { getState }) => {
    const state: any = getState();
    const token = state?.auth?.accessToken;
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }

    const connectionId = sessionStorage.getItem('connectionId');
    if (connectionId && connectionId !== '') {
      headers.set('x-signalr-connection', connectionId);
    }

    return headers;
  },

});

const baseQueryWithReauth = async (args: any, api: any, extraOptions: any) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    api.dispatch(logout());
  }
  return result;
};

export const emptySplitApi = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: (build) => ({}),
  tagTypes: [CacheTags.Task]
});
