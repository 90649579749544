import { Suspense, lazy, ElementType } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import AuthGuard from '../guards/AuthGuard';
// components
import LoadingScreen from '../components/LoadingScreen';
// context
import { AuthProvider } from '../contexts/AuthContext';
// redux
import { useSelector } from '../redux/store';
import { selectCurrentUser } from '../redux/slices/auth';
import { PATH_DASHBOARD } from './paths';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const user = useSelector(selectCurrentUser);

  const isDashboard =
    (pathname.includes('/list') ||
      pathname.includes('/kanban') ||
      pathname.includes('/scheduler') ||
      pathname.includes('/notes') ||
      pathname.includes('/connections')) &&
    !!user;

  return (
    <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
      <Component {...props} />
    </Suspense>
  );
};


// MAIN
const Page404 = Loadable(lazy(() => import('../pages/Page404')));
const LoginPage = Loadable(lazy(() => import('../pages/Login')));
//
const BriefmaticTaskList = Loadable(lazy(() => import('../pages/dashboard/BriefmaticTaskList')));
const BriefmaticTaskKanban = Loadable(lazy(() => import('../pages/dashboard/BriefmaticTaskKanban')));
const BriefmaticTaskScheduler = Loadable(lazy(() => import('../pages/dashboard/BriefmaticTaskScheduler')));
const BriefmaticConnections = Loadable(lazy(() => import('../pages/dashboard/BriefmaticConnections')));
const BriefmaticNotes = Loadable(lazy(() => import('../pages/dashboard/BriefmaticNotes')));
const BriefmaticSetup1 = Loadable(lazy(() => import('../pages/dashboard/BriefmaticSetup1')));
const BriefmaticSetup2 = Loadable(lazy(() => import('../pages/dashboard/BriefmaticSetup2')));
const BriefmaticSetup3 = Loadable(lazy(() => import('../pages/dashboard/BriefmaticSetup3')));


export default function Router() {

  return useRoutes([
    // Dashboard Routes
    {
      path: PATH_DASHBOARD.root,
      element: (
        <AuthGuard>
          <AuthProvider>
            <DashboardLayout />
          </AuthProvider>
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={`${PATH_DASHBOARD.root}/list`} replace />, index: true },
        { path: 'list', element: <BriefmaticTaskList /> },
        { path: 'list/task/:taskId', element: <BriefmaticTaskList /> },
        { path: 'kanban', element: <BriefmaticTaskKanban /> },
        { path: 'kanban/task/:taskId', element: <BriefmaticTaskKanban /> },
        { path: 'scheduler', element: <BriefmaticTaskScheduler /> },
        { path: 'connections', element: <BriefmaticConnections /> },
        { path: 'notes', element: <BriefmaticNotes /> },

      ],
    },

        // Setup Routes
        {
          path: PATH_DASHBOARD.root,
          element: (
            <AuthGuard>
              <AuthProvider>
                <LogoOnlyLayout />
              </AuthProvider>
            </AuthGuard>
          ),
          children: [
            { path: 'setup1', element: <BriefmaticSetup1 /> },
            { path: 'setup2', element: <BriefmaticSetup2 /> },
            { path: 'setup3', element: <BriefmaticSetup3 /> },

          ],
        },




    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <Page404 /> },
        { path: 'login', element: <LoginPage /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        {
          element: <LoginPage />,
          index: true,
        }
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

