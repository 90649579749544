import { Theme } from '@mui/material/styles';
import { pxToRem, responsiveFontSizes } from '../../utils/getFontValue';

// ----------------------------------------------------------------------

export default function Typography(theme: Theme) {
  return {
    MuiTypography: {
      styleOverrides: {
        paragraph: {
          marginBottom: theme.spacing(2)
        },
        gutterBottom: {
          marginBottom: theme.spacing(1)
        },
        h2: {
          fontWeight: 700,
          lineHeight: 64 / 56,
          fontSize: pxToRem(32),
          ...responsiveFontSizes({ sm: 32, md: 36, lg: 40 }),
        },
        h4: {
          fontWeight: 700,
          lineHeight: 1.5,
          fontSize: pxToRem(20),
          ...responsiveFontSizes({ sm: 18, md: 20, lg: 20 }),
        },
      }
    }
  };
}
