import { TaskUpdateActionCreator } from 'src/redux/actions/TaskUpdateActionCreator';
import { emptySplitApi as api, CacheTags } from './emptyApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    postV10UsersLogin: build.mutation<PostV10UsersLoginApiResponse, PostV10UsersLoginApiArg>({
      query: (queryArg) => ({
        url: `/v1.0/users/login`,
        method: 'POST',
        body: queryArg.loginRequest,
      }),
    }),
    postV10UsersRestart: build.mutation<PostV10UsersRestartApiResponse, PostV10UsersRestartApiArg>({
      query: (queryArg) => ({
        url: `/v1.0/users/restart`,
        method: 'POST',
        headers: { 'x-signalr-connection': queryArg['x-signalr-connection'] },
      }),
    }),
    postV10UsersLogout: build.mutation<PostV10UsersLogoutApiResponse, PostV10UsersLogoutApiArg>({
      query: (queryArg) => ({
        url: `/v1.0/users/logout`,
        method: 'POST',
        headers: { 'x-signalr-connection': queryArg['x-signalr-connection'] },
      }),
    }),
    postV10UsersDelete: build.mutation<PostV10UsersDeleteApiResponse, PostV10UsersDeleteApiArg>({
      query: (queryArg) => ({
        url: `/v1.0/users/delete`,
        method: 'POST',
        headers: { 'x-signalr-connection': queryArg['x-signalr-connection'] },
      }),
    }),
    getV10SpacesBySpaceId: build.query<
      GetV10SpacesBySpaceIdApiResponse,
      GetV10SpacesBySpaceIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1.0/spaces/${queryArg.spaceId}`,
        headers: { 'x-signalr-connection': queryArg['x-signalr-connection'] },
      }),
    }),
    getV10Spaces: build.query<GetV10SpacesApiResponse, GetV10SpacesApiArg>({
      query: (queryArg) => ({
        url: `/v1.0/spaces`,
        headers: { 'x-signalr-connection': queryArg['x-signalr-connection'] },
      }),
    }),
    getV10SpacesBySpaceIdLinkproviders: build.query<
      GetV10SpacesBySpaceIdLinkprovidersApiResponse,
      GetV10SpacesBySpaceIdLinkprovidersApiArg
    >({
      query: (queryArg) => ({
        url: `/v1.0/spaces/${queryArg.spaceId}/linkproviders`,
        headers: { 'x-signalr-connection': queryArg['x-signalr-connection'] },
      }),
    }),
    getV10SpacesBySpaceIdLinksAndId: build.query<
      GetV10SpacesBySpaceIdLinksAndIdApiResponse,
      GetV10SpacesBySpaceIdLinksAndIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1.0/spaces/${queryArg.spaceId}/links/${queryArg.id}`,
        headers: { 'x-signalr-connection': queryArg['x-signalr-connection'] },
      }),
    }),
    getV10SpacesBySpaceIdLinks: build.query<
      GetV10SpacesBySpaceIdLinksApiResponse,
      GetV10SpacesBySpaceIdLinksApiArg
    >({
      query: (queryArg) => ({
        url: `/v1.0/spaces/${queryArg.spaceId}/links`,
        headers: { 'x-signalr-connection': queryArg['x-signalr-connection'] },
      }),
    }),
    patchV10SpacesBySpaceIdLinks: build.mutation<
      PatchV10SpacesBySpaceIdLinksApiResponse,
      PatchV10SpacesBySpaceIdLinksApiArg
    >({
      query: (queryArg) => ({
        url: `/v1.0/spaces/${queryArg.spaceId}/links`,
        method: 'PATCH',
        body: queryArg.spaceLink,
        headers: { 'x-signalr-connection': queryArg['x-signalr-connection'] },
      }),
    }),
    postV10SpacesBySpaceIdLinksRequest: build.mutation<
      PostV10SpacesBySpaceIdLinksRequestApiResponse,
      PostV10SpacesBySpaceIdLinksRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/v1.0/spaces/${queryArg.spaceId}/links/request`,
        method: 'POST',
        body: queryArg.linkProviderType,
        headers: { 'x-signalr-connection': queryArg['x-signalr-connection'] },
      }),
    }),
    postV10SpacesBySpaceIdLinksAuthorize: build.mutation<
      PostV10SpacesBySpaceIdLinksAuthorizeApiResponse,
      PostV10SpacesBySpaceIdLinksAuthorizeApiArg
    >({
      query: (queryArg) => ({
        url: `/v1.0/spaces/${queryArg.spaceId}/links/authorize`,
        method: 'POST',
        body: queryArg.linkProviderType,
        headers: { 'x-signalr-connection': queryArg['x-signalr-connection'] },
      }),
    }),
    postV10SpacesBySpaceIdLinksConnect: build.mutation<
      PostV10SpacesBySpaceIdLinksConnectApiResponse,
      PostV10SpacesBySpaceIdLinksConnectApiArg
    >({
      query: (queryArg) => ({
        url: `/v1.0/spaces/${queryArg.spaceId}/links/connect`,
        method: 'POST',
        body: queryArg.spaceLinkConnectionRequest,
        headers: { 'x-signalr-connection': queryArg['x-signalr-connection'] },
      }),
    }),
    postV10SpacesBySpaceIdLinksDisconnectAndId: build.mutation<
      PostV10SpacesBySpaceIdLinksDisconnectAndIdApiResponse,
      PostV10SpacesBySpaceIdLinksDisconnectAndIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1.0/spaces/${queryArg.spaceId}/links/disconnect/${queryArg.id}`,
        method: 'POST',
        headers: { 'x-signalr-connection': queryArg['x-signalr-connection'] },
      }),
    }),
    getV10SpacesBySpaceIdStatusesAndId: build.query<
      GetV10SpacesBySpaceIdStatusesAndIdApiResponse,
      GetV10SpacesBySpaceIdStatusesAndIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1.0/spaces/${queryArg.spaceId}/statuses/${queryArg.id}`,
        headers: { 'x-signalr-connection': queryArg['x-signalr-connection'] },
      }),
    }),
    deleteV10SpacesBySpaceIdStatusesAndId: build.mutation<
      DeleteV10SpacesBySpaceIdStatusesAndIdApiResponse,
      DeleteV10SpacesBySpaceIdStatusesAndIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1.0/spaces/${queryArg.spaceId}/statuses/${queryArg.id}`,
        method: 'DELETE',
        headers: { 'x-signalr-connection': queryArg['x-signalr-connection'] },
      }),
    }),
    getV10SpacesBySpaceIdStatuses: build.query<
      GetV10SpacesBySpaceIdStatusesApiResponse,
      GetV10SpacesBySpaceIdStatusesApiArg
    >({
      query: (queryArg) => ({
        url: `/v1.0/spaces/${queryArg.spaceId}/statuses`,
        headers: { 'x-signalr-connection': queryArg['x-signalr-connection'] },
      }),
    }),
    postV10SpacesBySpaceIdStatuses: build.mutation<
      PostV10SpacesBySpaceIdStatusesApiResponse,
      PostV10SpacesBySpaceIdStatusesApiArg
    >({
      query: (queryArg) => ({
        url: `/v1.0/spaces/${queryArg.spaceId}/statuses`,
        method: 'POST',
        body: queryArg.newTaskStatus,
        headers: { 'x-signalr-connection': queryArg['x-signalr-connection'] },
      }),
    }),
    patchV10SpacesBySpaceIdStatuses: build.mutation<
      PatchV10SpacesBySpaceIdStatusesApiResponse,
      PatchV10SpacesBySpaceIdStatusesApiArg
    >({
      query: (queryArg) => ({
        url: `/v1.0/spaces/${queryArg.spaceId}/statuses`,
        method: 'PATCH',
        body: queryArg.taskStatus,
        headers: { 'x-signalr-connection': queryArg['x-signalr-connection'] },
      }),
    }),
    getV10SpacesBySpaceIdProjectsAndId: build.query<
      GetV10SpacesBySpaceIdProjectsAndIdApiResponse,
      GetV10SpacesBySpaceIdProjectsAndIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1.0/spaces/${queryArg.spaceId}/projects/${queryArg.id}`,
        headers: { 'x-signalr-connection': queryArg['x-signalr-connection'] },
      }),
    }),
    deleteV10SpacesBySpaceIdProjectsAndId: build.mutation<
      DeleteV10SpacesBySpaceIdProjectsAndIdApiResponse,
      DeleteV10SpacesBySpaceIdProjectsAndIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1.0/spaces/${queryArg.spaceId}/projects/${queryArg.id}`,
        method: 'DELETE',
        headers: { 'x-signalr-connection': queryArg['x-signalr-connection'] },
      }),
    }),
    getV10SpacesBySpaceIdProjects: build.query<
      GetV10SpacesBySpaceIdProjectsApiResponse,
      GetV10SpacesBySpaceIdProjectsApiArg
    >({
      query: (queryArg) => ({
        url: `/v1.0/spaces/${queryArg.spaceId}/projects`,
        headers: { 'x-signalr-connection': queryArg['x-signalr-connection'] },
      }),
    }),
    postV10SpacesBySpaceIdProjects: build.mutation<
      PostV10SpacesBySpaceIdProjectsApiResponse,
      PostV10SpacesBySpaceIdProjectsApiArg
    >({
      query: (queryArg) => ({
        url: `/v1.0/spaces/${queryArg.spaceId}/projects`,
        method: 'POST',
        body: queryArg.newTaskProject,
        headers: { 'x-signalr-connection': queryArg['x-signalr-connection'] },
      }),
    }),
    patchV10SpacesBySpaceIdProjects: build.mutation<
      PatchV10SpacesBySpaceIdProjectsApiResponse,
      PatchV10SpacesBySpaceIdProjectsApiArg
    >({
      query: (queryArg) => ({
        url: `/v1.0/spaces/${queryArg.spaceId}/projects`,
        method: 'PATCH',
        body: queryArg.taskProject,
        headers: { 'x-signalr-connection': queryArg['x-signalr-connection'] },
      }),
    }),
    getV10SpacesBySpaceIdNotesAndId: build.query<
      GetV10SpacesBySpaceIdNotesAndIdApiResponse,
      GetV10SpacesBySpaceIdNotesAndIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1.0/spaces/${queryArg.spaceId}/notes/${queryArg.id}`,
        headers: { 'x-signalr-connection': queryArg['x-signalr-connection'] },
      }),
    }),
    deleteV10SpacesBySpaceIdNotesAndId: build.mutation<
      DeleteV10SpacesBySpaceIdNotesAndIdApiResponse,
      DeleteV10SpacesBySpaceIdNotesAndIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1.0/spaces/${queryArg.spaceId}/notes/${queryArg.id}`,
        method: 'DELETE',
        headers: { 'x-signalr-connection': queryArg['x-signalr-connection'] },
      }),
    }),
    getV10SpacesBySpaceIdNotes: build.query<
      GetV10SpacesBySpaceIdNotesApiResponse,
      GetV10SpacesBySpaceIdNotesApiArg
    >({
      query: (queryArg) => ({
        url: `/v1.0/spaces/${queryArg.spaceId}/notes`,
        headers: { 'x-signalr-connection': queryArg['x-signalr-connection'] },
      }),
    }),
    postV10SpacesBySpaceIdNotes: build.mutation<
      PostV10SpacesBySpaceIdNotesApiResponse,
      PostV10SpacesBySpaceIdNotesApiArg
    >({
      query: (queryArg) => ({
        url: `/v1.0/spaces/${queryArg.spaceId}/notes`,
        method: 'POST',
        body: queryArg.newSpaceNote,
        headers: { 'x-signalr-connection': queryArg['x-signalr-connection'] },
      }),
    }),
    patchV10SpacesBySpaceIdNotes: build.mutation<
      PatchV10SpacesBySpaceIdNotesApiResponse,
      PatchV10SpacesBySpaceIdNotesApiArg
    >({
      query: (queryArg) => ({
        url: `/v1.0/spaces/${queryArg.spaceId}/notes`,
        method: 'PATCH',
        body: queryArg.spaceNote,
        headers: { 'x-signalr-connection': queryArg['x-signalr-connection'] },
      }),
    }),
    getV10SpacesBySpaceIdTasksAndId: build.query<
      GetV10SpacesBySpaceIdTasksAndIdApiResponse,
      GetV10SpacesBySpaceIdTasksAndIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1.0/spaces/${queryArg.spaceId}/tasks/${queryArg.id}`,
        headers: { 'x-signalr-connection': queryArg['x-signalr-connection'] },
      }),
    }),
    deleteV10SpacesBySpaceIdTasksAndId: build.mutation<
      DeleteV10SpacesBySpaceIdTasksAndIdApiResponse,
      DeleteV10SpacesBySpaceIdTasksAndIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1.0/spaces/${queryArg.spaceId}/tasks/${queryArg.id}`,
        method: 'DELETE',
        headers: { 'x-signalr-connection': queryArg['x-signalr-connection'] },
      }),
    }),
    getV10SpacesBySpaceIdTasks: build.query<
      GetV10SpacesBySpaceIdTasksApiResponse,
      GetV10SpacesBySpaceIdTasksApiArg
    >({
      query: (queryArg) => ({
        url: `/v1.0/spaces/${queryArg.spaceId}/tasks`,
        headers: { 'x-signalr-connection': queryArg['x-signalr-connection'] },
        params: { seen: queryArg.seen },
      }),
      providesTags: [CacheTags.Task]
    }),
    postV10SpacesBySpaceIdTasks: build.mutation<
      PostV10SpacesBySpaceIdTasksApiResponse,
      PostV10SpacesBySpaceIdTasksApiArg
    >({
      query: (queryArg) => ({
        url: `/v1.0/spaces/${queryArg.spaceId}/tasks`,
        method: 'POST',
        body: queryArg.newTaskItem,
        headers: { 'x-signalr-connection': queryArg['x-signalr-connection'] },
      }),
      async onQueryStarted({ spaceId }, { dispatch, queryFulfilled }) {
        try {
          const { data: newTask } = await queryFulfilled
          dispatch(TaskUpdateActionCreator(spaceId, [newTask]))
        } catch {
          dispatch(api.util.invalidateTags([CacheTags.Task]))
        }
      },
    }),
    patchV10SpacesBySpaceIdTasks: build.mutation<
      PatchV10SpacesBySpaceIdTasksApiResponse,
      PatchV10SpacesBySpaceIdTasksApiArg
    >({
      query: (queryArg) => ({
        url: `/v1.0/spaces/${queryArg.spaceId}/tasks`,
        method: 'PATCH',
        body: queryArg.taskItem,
        headers: { 'x-signalr-connection': queryArg['x-signalr-connection'] },
      }),
      async onQueryStarted({ spaceId }, { dispatch, queryFulfilled }) {
        try {
          const { data: newTask } = await queryFulfilled
          dispatch(TaskUpdateActionCreator(spaceId, [newTask]))
        } catch {
          dispatch(api.util.invalidateTags([CacheTags.Task]))
        }
      },
    }),
    postV10SpacesBySpaceIdTasksMove: build.mutation<
      PostV10SpacesBySpaceIdTasksMoveApiResponse,
      PostV10SpacesBySpaceIdTasksMoveApiArg
    >({
      query: (queryArg) => ({
        url: `/v1.0/spaces/${queryArg.spaceId}/tasks/move`,
        method: 'POST',
        body: queryArg.body,
        headers: { 'x-signalr-connection': queryArg['x-signalr-connection'] },
        params: { statusId: queryArg.statusId },
      }),
      async onQueryStarted({ spaceId }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedTasks } = await queryFulfilled
          dispatch(TaskUpdateActionCreator(spaceId, updatedTasks))
        } catch {
          dispatch(api.util.invalidateTags([CacheTags.Task]))
        }
      },
    }),
    postV10SpacesBySpaceIdTasksAndIdOpened: build.mutation<
      PostV10SpacesBySpaceIdTasksAndIdOpenedApiResponse,
      PostV10SpacesBySpaceIdTasksAndIdOpenedApiArg
    >({
      query: (queryArg) => ({
        url: `/v1.0/spaces/${queryArg.spaceId}/tasks/${queryArg.id}/opened`,
        method: 'POST',
        headers: { 'x-signalr-connection': queryArg['x-signalr-connection'] },
      }),
    }),
    getV10SpacesBySpaceIdTasksAndTaskIdCommentsId: build.query<
      GetV10SpacesBySpaceIdTasksAndTaskIdCommentsIdApiResponse,
      GetV10SpacesBySpaceIdTasksAndTaskIdCommentsIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1.0/spaces/${queryArg.spaceId}/tasks/${queryArg.taskId}/comments/${queryArg.id}`,
        headers: { 'x-signalr-connection': queryArg['x-signalr-connection'] },
      }),
    }),
    deleteV10SpacesBySpaceIdTasksAndTaskIdCommentsId: build.mutation<
      DeleteV10SpacesBySpaceIdTasksAndTaskIdCommentsIdApiResponse,
      DeleteV10SpacesBySpaceIdTasksAndTaskIdCommentsIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1.0/spaces/${queryArg.spaceId}/tasks/${queryArg.taskId}/comments/${queryArg.id}`,
        method: 'DELETE',
        headers: { 'x-signalr-connection': queryArg['x-signalr-connection'] },
      }),
    }),
    postV10SpacesBySpaceIdTasksAndTaskIdComments: build.mutation<
      PostV10SpacesBySpaceIdTasksAndTaskIdCommentsApiResponse,
      PostV10SpacesBySpaceIdTasksAndTaskIdCommentsApiArg
    >({
      query: (queryArg) => ({
        url: `/v1.0/spaces/${queryArg.spaceId}/tasks/${queryArg.taskId}/comments`,
        method: 'POST',
        body: queryArg.newTaskItemComment,
        headers: { 'x-signalr-connection': queryArg['x-signalr-connection'] },
      }),
    }),
    patchV10SpacesBySpaceIdTasksAndTaskIdComments: build.mutation<
      PatchV10SpacesBySpaceIdTasksAndTaskIdCommentsApiResponse,
      PatchV10SpacesBySpaceIdTasksAndTaskIdCommentsApiArg
    >({
      query: (queryArg) => ({
        url: `/v1.0/spaces/${queryArg.spaceId}/tasks/${queryArg.taskId}/comments`,
        method: 'PATCH',
        body: queryArg.taskItemComment,
        headers: { 'x-signalr-connection': queryArg['x-signalr-connection'] },
      }),
    }),
    getV10SpacesBySpaceIdTasksAndTaskIdScheduleId: build.query<
      GetV10SpacesBySpaceIdTasksAndTaskIdScheduleIdApiResponse,
      GetV10SpacesBySpaceIdTasksAndTaskIdScheduleIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1.0/spaces/${queryArg.spaceId}/tasks/${queryArg.taskId}/schedule/${queryArg.id}`,
        headers: { 'x-signalr-connection': queryArg['x-signalr-connection'] },
      }),
    }),
    deleteV10SpacesBySpaceIdTasksAndTaskIdScheduleId: build.mutation<
      DeleteV10SpacesBySpaceIdTasksAndTaskIdScheduleIdApiResponse,
      DeleteV10SpacesBySpaceIdTasksAndTaskIdScheduleIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1.0/spaces/${queryArg.spaceId}/tasks/${queryArg.taskId}/schedule/${queryArg.id}`,
        method: 'DELETE',
        headers: { 'x-signalr-connection': queryArg['x-signalr-connection'] },
      }),
    }),
    postV10SpacesBySpaceIdTasksAndTaskIdSchedule: build.mutation<
      PostV10SpacesBySpaceIdTasksAndTaskIdScheduleApiResponse,
      PostV10SpacesBySpaceIdTasksAndTaskIdScheduleApiArg
    >({
      query: (queryArg) => ({
        url: `/v1.0/spaces/${queryArg.spaceId}/tasks/${queryArg.taskId}/schedule`,
        method: 'POST',
        body: queryArg.newTaskItemScheduleEntry,
        headers: { 'x-signalr-connection': queryArg['x-signalr-connection'] },
      }),
    }),
    patchV10SpacesBySpaceIdTasksAndTaskIdSchedule: build.mutation<
      PatchV10SpacesBySpaceIdTasksAndTaskIdScheduleApiResponse,
      PatchV10SpacesBySpaceIdTasksAndTaskIdScheduleApiArg
    >({
      query: (queryArg) => ({
        url: `/v1.0/spaces/${queryArg.spaceId}/tasks/${queryArg.taskId}/schedule`,
        method: 'PATCH',
        body: queryArg.taskItemScheduleEntry,
        headers: { 'x-signalr-connection': queryArg['x-signalr-connection'] },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as coreApi };
export type PostV10UsersLoginApiResponse = /** status 200 OK */ StartupData;
export type PostV10UsersLoginApiArg = {
  loginRequest: LoginRequest;
};
export type PostV10UsersRestartApiResponse = /** status 200 OK */ StartupData;
export type PostV10UsersRestartApiArg = {
  /** Signal R Connection Id */
  'x-signalr-connection'?: string;
};
export type PostV10UsersLogoutApiResponse = unknown;
export type PostV10UsersLogoutApiArg = {
  /** Signal R Connection Id */
  'x-signalr-connection'?: string;
};
export type PostV10UsersDeleteApiResponse = unknown;
export type PostV10UsersDeleteApiArg = {
  /** Signal R Connection Id */
  'x-signalr-connection'?: string;
};
export type GetV10SpacesBySpaceIdApiResponse = /** status 200 OK */ Space;
export type GetV10SpacesBySpaceIdApiArg = {
  spaceId: string;
  /** Signal R Connection Id */
  'x-signalr-connection'?: string;
};
export type GetV10SpacesApiResponse = /** status 200 OK */ Space[];
export type GetV10SpacesApiArg = {
  /** Signal R Connection Id */
  'x-signalr-connection'?: string;
};
export type GetV10SpacesBySpaceIdLinkprovidersApiResponse = /** status 200 OK */ LinkProvider[];
export type GetV10SpacesBySpaceIdLinkprovidersApiArg = {
  spaceId: string;
  /** Signal R Connection Id */
  'x-signalr-connection'?: string;
};
export type GetV10SpacesBySpaceIdLinksAndIdApiResponse = /** status 200 OK */ SpaceLink;
export type GetV10SpacesBySpaceIdLinksAndIdApiArg = {
  id: string;
  spaceId: string;
  /** Signal R Connection Id */
  'x-signalr-connection'?: string;
};
export type GetV10SpacesBySpaceIdLinksApiResponse = /** status 200 OK */ SpaceLink[];
export type GetV10SpacesBySpaceIdLinksApiArg = {
  spaceId: string;
  /** Signal R Connection Id */
  'x-signalr-connection'?: string;
};
export type PatchV10SpacesBySpaceIdLinksApiResponse = /** status 200 OK */ SpaceLink;
export type PatchV10SpacesBySpaceIdLinksApiArg = {
  spaceId: string;
  /** Signal R Connection Id */
  'x-signalr-connection'?: string;
  spaceLink: SpaceLink;
};
export type PostV10SpacesBySpaceIdLinksRequestApiResponse = /** status 201 Created */ SpaceLink;
export type PostV10SpacesBySpaceIdLinksRequestApiArg = {
  spaceId: string;
  /** Signal R Connection Id */
  'x-signalr-connection'?: string;
  linkProviderType: LinkProviderType;
};
export type PostV10SpacesBySpaceIdLinksAuthorizeApiResponse = /** status 200 OK */ string;
export type PostV10SpacesBySpaceIdLinksAuthorizeApiArg = {
  spaceId: string;
  /** Signal R Connection Id */
  'x-signalr-connection'?: string;
  linkProviderType: LinkProviderType;
};
export type PostV10SpacesBySpaceIdLinksConnectApiResponse = /** status 201 Created */ SpaceLink;
export type PostV10SpacesBySpaceIdLinksConnectApiArg = {
  spaceId: string;
  /** Signal R Connection Id */
  'x-signalr-connection'?: string;
  spaceLinkConnectionRequest: SpaceLinkConnectionRequest;
};
export type PostV10SpacesBySpaceIdLinksDisconnectAndIdApiResponse =
  /** status 201 Created */ SpaceLink;
export type PostV10SpacesBySpaceIdLinksDisconnectAndIdApiArg = {
  id: string;
  spaceId: string;
  /** Signal R Connection Id */
  'x-signalr-connection'?: string;
};
export type GetV10SpacesBySpaceIdStatusesAndIdApiResponse = /** status 200 OK */ TaskStatus;
export type GetV10SpacesBySpaceIdStatusesAndIdApiArg = {
  id: string;
  spaceId: string;
  /** Signal R Connection Id */
  'x-signalr-connection'?: string;
};
export type DeleteV10SpacesBySpaceIdStatusesAndIdApiResponse = unknown;
export type DeleteV10SpacesBySpaceIdStatusesAndIdApiArg = {
  id: string;
  spaceId: string;
  /** Signal R Connection Id */
  'x-signalr-connection'?: string;
};
export type GetV10SpacesBySpaceIdStatusesApiResponse = /** status 200 OK */ TaskStatus[];
export type GetV10SpacesBySpaceIdStatusesApiArg = {
  spaceId: string;
  /** Signal R Connection Id */
  'x-signalr-connection'?: string;
};
export type PostV10SpacesBySpaceIdStatusesApiResponse = /** status 201 Created */ TaskStatus[];
export type PostV10SpacesBySpaceIdStatusesApiArg = {
  spaceId: string;
  /** Signal R Connection Id */
  'x-signalr-connection'?: string;
  newTaskStatus: NewTaskStatus;
};
export type PatchV10SpacesBySpaceIdStatusesApiResponse = /** status 200 OK */ TaskStatus[];
export type PatchV10SpacesBySpaceIdStatusesApiArg = {
  spaceId: string;
  /** Signal R Connection Id */
  'x-signalr-connection'?: string;
  taskStatus: TaskStatus;
};
export type GetV10SpacesBySpaceIdProjectsAndIdApiResponse = /** status 200 OK */ TaskProject;
export type GetV10SpacesBySpaceIdProjectsAndIdApiArg = {
  id: string;
  spaceId: string;
  /** Signal R Connection Id */
  'x-signalr-connection'?: string;
};
export type DeleteV10SpacesBySpaceIdProjectsAndIdApiResponse = unknown;
export type DeleteV10SpacesBySpaceIdProjectsAndIdApiArg = {
  id: string;
  spaceId: string;
  /** Signal R Connection Id */
  'x-signalr-connection'?: string;
};
export type GetV10SpacesBySpaceIdProjectsApiResponse = /** status 200 OK */ TaskProject[];
export type GetV10SpacesBySpaceIdProjectsApiArg = {
  spaceId: string;
  /** Signal R Connection Id */
  'x-signalr-connection'?: string;
};
export type PostV10SpacesBySpaceIdProjectsApiResponse = /** status 201 Created */ TaskProject;
export type PostV10SpacesBySpaceIdProjectsApiArg = {
  spaceId: string;
  /** Signal R Connection Id */
  'x-signalr-connection'?: string;
  newTaskProject: NewTaskProject;
};
export type PatchV10SpacesBySpaceIdProjectsApiResponse = /** status 200 OK */ TaskProject;
export type PatchV10SpacesBySpaceIdProjectsApiArg = {
  spaceId: string;
  /** Signal R Connection Id */
  'x-signalr-connection'?: string;
  taskProject: TaskProject;
};
export type GetV10SpacesBySpaceIdNotesAndIdApiResponse = /** status 200 OK */ SpaceNote;
export type GetV10SpacesBySpaceIdNotesAndIdApiArg = {
  id: string;
  spaceId: string;
  /** Signal R Connection Id */
  'x-signalr-connection'?: string;
};
export type DeleteV10SpacesBySpaceIdNotesAndIdApiResponse = /** status 200 OK */ SpaceNote;
export type DeleteV10SpacesBySpaceIdNotesAndIdApiArg = {
  id: string;
  spaceId: string;
  /** Signal R Connection Id */
  'x-signalr-connection'?: string;
};
export type GetV10SpacesBySpaceIdNotesApiResponse = /** status 200 OK */ TaskStatus[];
export type GetV10SpacesBySpaceIdNotesApiArg = {
  spaceId: string;
  /** Signal R Connection Id */
  'x-signalr-connection'?: string;
};
export type PostV10SpacesBySpaceIdNotesApiResponse = /** status 201 Created */ SpaceNote;
export type PostV10SpacesBySpaceIdNotesApiArg = {
  spaceId: string;
  /** Signal R Connection Id */
  'x-signalr-connection'?: string;
  newSpaceNote: NewSpaceNote;
};
export type PatchV10SpacesBySpaceIdNotesApiResponse = /** status 200 OK */ SpaceNote;
export type PatchV10SpacesBySpaceIdNotesApiArg = {
  spaceId: string;
  /** Signal R Connection Id */
  'x-signalr-connection'?: string;
  spaceNote: SpaceNote;
};
export type GetV10SpacesBySpaceIdTasksAndIdApiResponse = /** status 200 OK */ TaskItem;
export type GetV10SpacesBySpaceIdTasksAndIdApiArg = {
  id: string;
  spaceId: string;
  /** Signal R Connection Id */
  'x-signalr-connection'?: string;
};
export type DeleteV10SpacesBySpaceIdTasksAndIdApiResponse = /** status 200 OK */ TaskItem;
export type DeleteV10SpacesBySpaceIdTasksAndIdApiArg = {
  id: string;
  spaceId: string;
  /** Signal R Connection Id */
  'x-signalr-connection'?: string;
};
export type GetV10SpacesBySpaceIdTasksApiResponse = /** status 200 OK */ TaskItem[];
export type GetV10SpacesBySpaceIdTasksApiArg = {
  seen?: boolean;
  spaceId: string;
  /** Signal R Connection Id */
  'x-signalr-connection'?: string;
};
export type PostV10SpacesBySpaceIdTasksApiResponse = /** status 201 Created */ TaskItem;
export type PostV10SpacesBySpaceIdTasksApiArg = {
  spaceId: string;
  /** Signal R Connection Id */
  'x-signalr-connection'?: string;
  newTaskItem: NewTaskItem;
};
export type PatchV10SpacesBySpaceIdTasksApiResponse = /** status 200 OK */ TaskItem;
export type PatchV10SpacesBySpaceIdTasksApiArg = {
  spaceId: string;
  /** Signal R Connection Id */
  'x-signalr-connection'?: string;
  taskItem: TaskItem;
};
export type PostV10SpacesBySpaceIdTasksMoveApiResponse = /** status 200 OK */ TaskItem[];
export type PostV10SpacesBySpaceIdTasksMoveApiArg = {
  statusId?: string;
  spaceId: string;
  /** Signal R Connection Id */
  'x-signalr-connection'?: string;
  body: string[];
};
export type PostV10SpacesBySpaceIdTasksAndIdOpenedApiResponse = unknown;
export type PostV10SpacesBySpaceIdTasksAndIdOpenedApiArg = {
  id: string;
  spaceId: string;
  /** Signal R Connection Id */
  'x-signalr-connection'?: string;
};
export type GetV10SpacesBySpaceIdTasksAndTaskIdCommentsIdApiResponse =
  /** status 200 OK */ TaskItem;
export type GetV10SpacesBySpaceIdTasksAndTaskIdCommentsIdApiArg = {
  taskId: string;
  id: string;
  spaceId: string;
  /** Signal R Connection Id */
  'x-signalr-connection'?: string;
};
export type DeleteV10SpacesBySpaceIdTasksAndTaskIdCommentsIdApiResponse =
  /** status 200 OK */ TaskItemComment;
export type DeleteV10SpacesBySpaceIdTasksAndTaskIdCommentsIdApiArg = {
  taskId: string;
  id: string;
  spaceId: string;
  /** Signal R Connection Id */
  'x-signalr-connection'?: string;
};
export type PostV10SpacesBySpaceIdTasksAndTaskIdCommentsApiResponse =
  /** status 201 Created */ TaskItemComment;
export type PostV10SpacesBySpaceIdTasksAndTaskIdCommentsApiArg = {
  taskId: string;
  spaceId: string;
  /** Signal R Connection Id */
  'x-signalr-connection'?: string;
  newTaskItemComment: NewTaskItemComment;
};
export type PatchV10SpacesBySpaceIdTasksAndTaskIdCommentsApiResponse =
  /** status 200 OK */ TaskItemComment;
export type PatchV10SpacesBySpaceIdTasksAndTaskIdCommentsApiArg = {
  taskId: string;
  spaceId: string;
  /** Signal R Connection Id */
  'x-signalr-connection'?: string;
  taskItemComment: TaskItemComment;
};
export type GetV10SpacesBySpaceIdTasksAndTaskIdScheduleIdApiResponse =
  /** status 200 OK */ TaskItemScheduleEntry;
export type GetV10SpacesBySpaceIdTasksAndTaskIdScheduleIdApiArg = {
  taskId: string;
  id: string;
  spaceId: string;
  /** Signal R Connection Id */
  'x-signalr-connection'?: string;
};
export type DeleteV10SpacesBySpaceIdTasksAndTaskIdScheduleIdApiResponse =
  /** status 200 OK */ TaskItemScheduleEntry;
export type DeleteV10SpacesBySpaceIdTasksAndTaskIdScheduleIdApiArg = {
  taskId: string;
  id: string;
  spaceId: string;
  /** Signal R Connection Id */
  'x-signalr-connection'?: string;
};
export type PostV10SpacesBySpaceIdTasksAndTaskIdScheduleApiResponse =
  /** status 201 Created */ TaskItemScheduleEntry;
export type PostV10SpacesBySpaceIdTasksAndTaskIdScheduleApiArg = {
  taskId: string;
  spaceId: string;
  /** Signal R Connection Id */
  'x-signalr-connection'?: string;
  newTaskItemScheduleEntry: NewTaskItemScheduleEntry;
};
export type PatchV10SpacesBySpaceIdTasksAndTaskIdScheduleApiResponse =
  /** status 200 OK */ TaskItemScheduleEntry;
export type PatchV10SpacesBySpaceIdTasksAndTaskIdScheduleApiArg = {
  taskId: string;
  spaceId: string;
  /** Signal R Connection Id */
  'x-signalr-connection'?: string;
  taskItemScheduleEntry: TaskItemScheduleEntry;
};
export type UserType = 'Standard' | 'Karoro';
export type User = {
  id: string;
  type?: UserType;
  email: string;
  domain?: string | null;
  firstName: string;
  lastName?: string | null;
  name: string;
  locale: string;
  timezone: string;
  thumbnailUrl?: string | null;
  intercomUserVerificationHash: string;
  numberOfDaysToShowFinishedTasksFor: number;
};
export type AccessMode = 'Owner' | 'Administrator' | 'TaskReadWrite' | 'TaskComment' | 'TaskRead';
export type LinkProviderCategory = 'TaskSource' | 'TaskScheduler';
export type LinkProviderType =
  | 'System'
  | 'Manual'
  | 'GoogleDrive'
  | 'Gmail'
  | 'Confluence'
  | 'Figma'
  | 'Hubspot'
  | 'Intercom'
  | 'Jira'
  | 'Miro'
  | 'Microsoft365'
  | 'Slack'
  | 'Zendesk'
  | 'Notion'
  | 'GoogleTasks'
  | 'GoogleKeep'
  | 'GoogleCalendar'
  | 'MicrosoftTeams'
  | 'IMAP'
  | 'GitHub'
  | 'GitLab'
  | 'WhatsApp'
  | 'Inbox'
  | 'Chrome';
export type LinkState =
  | 'EarlyAccessRequested'
  | 'EarlyAccessAvailable'
  | 'NotSyncable'
  | 'InitialSyncNotYetAttempted'
  | 'SyncInProgress'
  | 'Operational'
  | 'ReauthenticationRequired'
  | 'Failed'
  | 'Disconnected'
  | 'ConfigurationRequired';
export type SpaceLink = {
  id: string;
  category: LinkProviderCategory;
  type: LinkProviderType;
  sortOrder: number;
  status: LinkState;
  externalAccountEmail?: string | null;
  externalAccountName?: string | null;
  externalInstanceId?: string | null;
  externalInstanceName?: string | null;
  name: string;
  iconUrl: string;
  lastSyncTime?: string | null;
  isConnected: boolean;
};
export type StatusType = 'ToDo' | 'InProgress' | 'Done' | 'Deleted';
export type TaskStatus = {
  id: string;
  type?: StatusType;
  name: string;
  sortOrder: number;
  color: string;
  textColor?: string;
  isDeletable?: boolean;
  isVisible?: boolean;
};
export type TaskProject = {
  id: string;
  name: string;
};
export type SpaceNote = {
  id: string;
  sortOrder: number;
  title?: string | null;
  content?: string | null;
  isDeleted?: boolean;
};
export type Space = {
  id: string;
  ownerUserId: string;
  accessMode: AccessMode;
  sortOrder: number;
  name: string;
  links: SpaceLink[];
  statuses: TaskStatus[];
  projects: TaskProject[];
  notes: SpaceNote[];
};
export type StartupData = {
  user: User;
  spaces: Space[];
  accessToken: string;
};
export type UserIdentityProviderType = 'Google';
export type EndpointType = 'Browser' | 'BrowserExtension';
export type LoginRequest = {
  identityProviderType: UserIdentityProviderType;
  endpointType: EndpointType;
  locale: string;
  timezone: string;
  method: string;
  claim: string;
};
export type LinkProvider = {
  type: LinkProviderType;
  category: LinkProviderCategory;
  sortOrder: number;
  name: string;
  description?: string | null;
  visible: boolean;
  generallyAvailable: boolean;
  supportsMultipleLinks: boolean;
  iconUrl: string;
  pictureUrl: string;
};
export type SpaceLinkConnectionRequest = {
  provider: LinkProviderType;
  credential: string;
};
export type NewTaskStatus = {
  name: string;
  sortOrder: number;
};
export type NewTaskProject = {
  name: string;
};
export type NewSpaceNote = {
  title?: string | null;
  content?: string | null;
};
export type TaskItemAssignmentType = 'Manual' | 'Tagged' | 'Assigned' | 'All';
export type PriorityLevel = 'Low' | 'Medium' | 'High';
export type ActorType = 'User' | 'Person' | 'System';
export type Actor = {
  id: string;
  type?: ActorType;
  name?: string;
  email?: string | null;
  thumbnailUrl?: string | null;
};
export type TaskItemComment = {
  id?: string;
  description?: string;
  createdOn?: string;
  isDeleted?: boolean;
  createdBy?: Actor;
  hasUnseenUpdates?: boolean;
};
export type TaskItemScheduleEntry = {
  id: string;
  start: string;
  end: string;
  isAllDay: boolean;
  isDeleted?: boolean;
};
export type Document = {
  id: string;
  name?: string | null;
  path?: string | null;
  url?: string | null;
  thumbnailUrl?: string | null;
  mimeType?: string | null;
};
export type TaskItem = {
  id: string;
  spaceLinkId?: string;
  assignmentType?: TaskItemAssignmentType;
  externalTitle?: string | null;
  externalDescription?: string | null;
  externalQuotedContent?: string | null;
  externalUrl?: string | null;
  externalDueBy?: string | null;
  statusId: string;
  projectId?: string | null;
  title?: string | null;
  description?: string | null;
  priority: PriorityLevel;
  duration?: number | null;
  dueBy?: string | null;
  rank: string;
  originator?: Actor;
  comments?: TaskItemComment[] | null;
  scheduleEntries?: TaskItemScheduleEntry[] | null;
  createdOn?: string;
  modifiedOn?: string;
  completedOn?: string | null;
  canAddComment?: boolean;
  hasUnseenUpdates?: boolean;
  hasUnopenedUpdates?: boolean;
  iconUrl?: string;
  sourceDocument?: Document;
};
export type NewTaskItemScheduleEntry = {
  start: string;
  end: string;
  isAllDay: boolean;
};
export type NewTaskItem = {
  rank?: string | null;
  title?: string | null;
  description?: string | null;
  priority?: PriorityLevel;
  dueBy?: string | null;
  statusId?: string | null;
  scheduleEntry?: NewTaskItemScheduleEntry;
};
export type NewTaskItemComment = {
  description: string;
};
export const {
  usePostV10UsersLoginMutation,
  usePostV10UsersRestartMutation,
  usePostV10UsersLogoutMutation,
  usePostV10UsersDeleteMutation,
  useGetV10SpacesBySpaceIdQuery,
  useGetV10SpacesQuery,
  useGetV10SpacesBySpaceIdLinkprovidersQuery,
  useGetV10SpacesBySpaceIdLinksAndIdQuery,
  useGetV10SpacesBySpaceIdLinksQuery,
  usePatchV10SpacesBySpaceIdLinksMutation,
  usePostV10SpacesBySpaceIdLinksRequestMutation,
  usePostV10SpacesBySpaceIdLinksAuthorizeMutation,
  usePostV10SpacesBySpaceIdLinksConnectMutation,
  usePostV10SpacesBySpaceIdLinksDisconnectAndIdMutation,
  useGetV10SpacesBySpaceIdStatusesAndIdQuery,
  useDeleteV10SpacesBySpaceIdStatusesAndIdMutation,
  useGetV10SpacesBySpaceIdStatusesQuery,
  usePostV10SpacesBySpaceIdStatusesMutation,
  usePatchV10SpacesBySpaceIdStatusesMutation,
  useGetV10SpacesBySpaceIdProjectsAndIdQuery,
  useDeleteV10SpacesBySpaceIdProjectsAndIdMutation,
  useGetV10SpacesBySpaceIdProjectsQuery,
  usePostV10SpacesBySpaceIdProjectsMutation,
  usePatchV10SpacesBySpaceIdProjectsMutation,
  useGetV10SpacesBySpaceIdNotesAndIdQuery,
  useDeleteV10SpacesBySpaceIdNotesAndIdMutation,
  useGetV10SpacesBySpaceIdNotesQuery,
  usePostV10SpacesBySpaceIdNotesMutation,
  usePatchV10SpacesBySpaceIdNotesMutation,
  useGetV10SpacesBySpaceIdTasksAndIdQuery,
  useDeleteV10SpacesBySpaceIdTasksAndIdMutation,
  useGetV10SpacesBySpaceIdTasksQuery,
  usePostV10SpacesBySpaceIdTasksMutation,
  usePatchV10SpacesBySpaceIdTasksMutation,
  usePostV10SpacesBySpaceIdTasksMoveMutation,
  usePostV10SpacesBySpaceIdTasksAndIdOpenedMutation,
  useGetV10SpacesBySpaceIdTasksAndTaskIdCommentsIdQuery,
  useDeleteV10SpacesBySpaceIdTasksAndTaskIdCommentsIdMutation,
  usePostV10SpacesBySpaceIdTasksAndTaskIdCommentsMutation,
  usePatchV10SpacesBySpaceIdTasksAndTaskIdCommentsMutation,
  useGetV10SpacesBySpaceIdTasksAndTaskIdScheduleIdQuery,
  useDeleteV10SpacesBySpaceIdTasksAndTaskIdScheduleIdMutation,
  usePostV10SpacesBySpaceIdTasksAndTaskIdScheduleMutation,
  usePatchV10SpacesBySpaceIdTasksAndTaskIdScheduleMutation,
} = injectedRtkApi;
