import _ from 'lodash';
import { TaskItemScheduleEntry, coreApi } from 'src/redux/coreApi';

export const TaskScheduleEntryUpdateActionCreator = (spaceId: string, taskId: string, data: TaskItemScheduleEntry[]) =>
  coreApi.util.updateQueryData('getV10SpacesBySpaceIdTasks', { spaceId: spaceId }, (draft) => {
    const index = draft.findIndex((taskItem) => taskItem.id === taskId);
    if (index >= 0) {
      draft[index].scheduleEntries = _.unionBy(data, draft[index].scheduleEntries, 'id');
    }
  });
