import { createSlice } from '@reduxjs/toolkit';
import { coreApi } from '../coreApi';
import { AuthState } from '../../@types/auth';
import { RootState } from '../store';

const handleLogout = (state: any) => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('user');
  sessionStorage.removeItem('connectionId');

  state.accessToken = '';
  state.user = null;

  if (window.Intercom != null) {
    window.Intercom('shutdown');
  }

  if (window.rg4js != null) {
    window.rg4js('endsession');
    window.rg4js('setUser', { isAnonymous: true });
  }

  if (window.FS != null) {
    window.FS.anonymize();
  }
};

const slice = createSlice({
  name: 'auth',
  initialState: () => {
    const accessToken = localStorage.getItem('accessToken');
    const user = localStorage.getItem('user');

    if (accessToken && user) {
      return {
        accessToken,
        user: JSON.parse(user),
      } as AuthState;
    } else {
      return { accessToken: undefined, user: undefined } as AuthState;
    }
  },
  reducers: {
    logout(state) {
      handleLogout(state);
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(coreApi.endpoints.postV10UsersLogin.matchFulfilled, (state, action) => {
      const { payload } = action;

      localStorage.setItem('accessToken', payload.accessToken || '');
      localStorage.setItem('user', payload.user ? JSON.stringify(payload.user) : '');

      state.accessToken = payload.accessToken;
      state.user = payload.user;
      state.space = payload.spaces.find(x => x.sortOrder === 1);
      state.lastLoginTime = new Date();
    });
  },
});

export default slice.reducer;
export const { logout } = slice.actions;
export const selectCurrentUser = (state: RootState) => state.auth.user;
export const selectAuthState = (state: RootState) => state.auth;
