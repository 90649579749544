// utils
import createAvatar from '../utils/createAvatar';
//
import Avatar, { Props as AvatarProps } from './Avatar';
// redux
import { selectCurrentUser } from '../redux/slices/auth';
import { useSelector } from '../redux/store';
// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }: AvatarProps) {
  const user = useSelector(selectCurrentUser);
  const { thumbnailUrl, name = '' } = user || {};

  return (
    <Avatar
      src={thumbnailUrl || ''}
      alt={name}
      color={thumbnailUrl ? 'default' : createAvatar(name).color}
      {...other}
    >
      {createAvatar(name).name}
    </Avatar>
  );
}
