import { ReactNode, createContext, useEffect, useReducer } from 'react';
import * as signalR from '@microsoft/signalr';
// types
import { AuthUser } from '../@types/auth';
//redux
import { Space, SpaceLink, SpaceNote, TaskStatus, usePostV10UsersRestartMutation } from '../redux/coreApi';
//config
import { HOST_API } from '../config';
import { useSelector } from 'react-redux';
import { selectAuthState } from 'src/redux/slices/auth';
// ----------------------------------------------------------------------

const links = [] as SpaceLink[];
const notes = [] as SpaceNote[];

export type ContextType = {
  user: AuthUser;
  space: Space;
  connection: signalR.HubConnection;
  updateStatues: (statuses: TaskStatus[]) => void;
  updateLink: (link: SpaceLink) => void;
  updateNote: (note: SpaceNote) => void;
};

export const AuthContext = createContext<ContextType | null>(null);

enum Types {
  Initial = 'INITIALIZE',
  Modify = 'MODIFY',
}

export type AuthState = {
  user: AuthUser | null;
  space: Space | null;
  connection: signalR.HubConnection | null;
};

const initialState: AuthState = {
  user: null,
  space: null,
  connection: null,
};

const reducer = (state: any, action: any) => {
  if (action.type === Types.Initial) {
    const { user, space, connection } = action.payload;

    return {
      ...state,
      user,
      space,
      connection,
    };
  }

  if (action.type === Types.Modify) {
    const { space } = action.payload;
    return {
      ...state,
      space,
    };
  }

  return state;
};

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [restart] = usePostV10UsersRestartMutation();
  const authState = useSelector(selectAuthState);

  useEffect(() => {

    const setGlobalUserInfo = (user: AuthUser) => {
      global.UserInfo = {
        user_id: user.id!,
        user_hash: user.intercomUserVerificationHash!,
      };

      if (window.Intercom != null) {
        window.Intercom("boot", global.UserInfo);
      }

      if (window.rg4js != null) {
        window.rg4js('setUser', {
          isAnonymous: false,
          identifier: user.id,
          firstName: user.firstName,
          fullName: user.name,
          email: user.email
        });
      }

      if (window.FS != null) {
        window.FS.identify(user.id, {
          displayName: user.name,
          email: user.email,
        });
      }
    }

    const initializeSignalRConnection = (accessToken: string) => {
      const connection = new signalR.HubConnectionBuilder()
        .withUrl(`${HOST_API}/sync`, {
          transport: signalR.HttpTransportType.WebSockets,
          accessTokenFactory: () => accessToken,
        })
        .withAutomaticReconnect()
        .configureLogging(signalR.LogLevel.Information)
        .build();

      connection
        .start()
        .then(() => sessionStorage.setItem('connectionId', connection.connectionId || ''))
        .catch((err) => console.error(err));

      return connection;
    }

    const rememberedLogin = async () => {
      const rs: any = await restart({});
      if (!rs.error) {
        const { user, accessToken, spaces = [] } = rs.data;
        const space = spaces.find((s: Space) => s.sortOrder === 1);
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('user', JSON.stringify(user));
        return { accessToken, user, space };
      }
      return { accessToken: null, user: null, space: null };
    }

    const initialize = async () => {
      try {
        const { user, accessToken, space } = authState.lastLoginTime ? authState : await rememberedLogin();
        if (user && space && accessToken) {

          setGlobalUserInfo(user);
          const connection = initializeSignalRConnection(accessToken);
          dispatch({
            type: Types.Initial,
            payload: {
              user,
              space,
              connection,
            },
          });
        }

      } catch (err) {
        console.error(err);
        dispatch({
          type: Types.Initial,
          payload: {
            user: null,
            space: null,
            connection: null,
          },
        });
      }
    };

    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateStatues = (statuses: TaskStatus[]) => {
    dispatch({
      type: Types.Modify,
      payload: {
        space: { ...state.space, statuses },
      },
    });
  };

  const updateLink = (link: SpaceLink) => {
    if(links.length === 0) {
      for (var i = 0; i < state.space.links.length; i++) {
        links.push(state.space.links[i]);
      }
    }

    const index = links.findIndex((x: SpaceLink) => x.id === link.id);
    if(index >= 0) {
      links[index] = link;
    }
    else {
      links.push(link);
    }

    dispatch({
      type: Types.Modify,
      payload: {
        space: { ...state.space, links, notes: notes.length > 0 ? notes : state.space.notes },
      },
    });
  };

  const updateNote = (note: SpaceNote) => {
    if (notes.length === 0) {
      for (var i = 0; i < state.space.notes.length; i++) {
        notes.push(state.space.notes[i]);
      }
    }

    const index = notes.findIndex((x: SpaceNote) => x.id === note.id);
    if (index >= 0) {
      notes[index] = note;
    }
    else {
      notes.push(note);
    }

    dispatch({
      type: Types.Modify,
      payload: {
        space: { ...state.space, links: links.length > 0 ? links : state.space.links, notes },
      },
    });
  };


  if (!state.user || !state.space) {
    return null;
  }

  return (
    <AuthContext.Provider value={{ ...state, updateStatues, updateLink, updateNote }}>{children}</AuthContext.Provider>
  );
};
