import { TaskItem, coreApi } from "src/redux/coreApi"

export const TaskUpdateActionCreator = (spaceId: string, updatedTasks: TaskItem[]) => (
  coreApi.util.updateQueryData('getV10SpacesBySpaceIdTasks', { spaceId: spaceId }, (draft) => {
    updatedTasks.forEach(x => {
      const index = draft.findIndex(taskItem => taskItem.id === x.id)
      if (index < 0) draft.push(x);
      else draft[index] = x;
    })
  }));

export const TaskOpenedActionCreator = (spaceId: string, taskId: string) => (
  coreApi.util.updateQueryData('getV10SpacesBySpaceIdTasks', { spaceId: spaceId }, (draft) => {
    const index = draft.findIndex(taskItem => taskItem.id === taskId)
    if (index > 0) draft[index] = {
      ...draft[index],
      hasUnseenUpdates: false,
      hasUnopenedUpdates: false
    }
  }));